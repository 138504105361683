






















































































































































































































































import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import CusDatePicker from '../controls/cus-date-picker.vue';
import CusDatePickerRange from '../controls/cus-date-picker-range.vue';
import SortableList from '../controls/sortable-list.vue';
import AccordionList from '../controls/accordion-list.vue';
import Ubigeo from '../controls/ubigeo.vue';
import Loader from '../loader.vue';
import Util from '../../lib/util'
import url from '@/lib/url';
import AbpBase from '../../lib/abpbase';
import { isArray } from 'util';
import { mask } from 'vue-the-mask'

@Component({
    components: { CusDatePicker, CusDatePickerRange, SortableList, Loader, AccordionList, Ubigeo },
    directives: {
        mask,
    },
    watch: {
        value: function (val, oldValue) {
            if (val && !oldValue) {
                (this as any).initForm();
            } 
        }
    }
}) 

export default class FormBase extends AbpBase {
    @Prop({ type: Object }) model: object;
    @Prop({ type: Boolean, default: false }) value: boolean;
    @Prop({ type: Boolean, default: false }) disabled: boolean;
    @Prop({ type: String, default: '' }) containerClass: string;
    @Prop({ type: Array, default: () => { return [{ key: 'main', title: '' }] }}) tabs: Array<any>;
    @Prop({ type: Array }) items: Array<any>;
    valid: boolean = false;  
    tabModel: any = null;
    showLoader: boolean = false;
    imgUrl: string = url;  

    get isNew() {
        return (this as any).model.id == undefined || (this as any).model.id == null;
    };

    get formLoader(): boolean {
        let result: boolean = false;
        if (this.showLoader) return true;
        this.items.forEach(item => {
            if ((item.controltype == 'select' && !item.autocomplete) || item.controltype == 'radio' ||
                item.controltype == 'checkboxlist' || item.type == 'sortablelist' || item.type == 'treeview' || item.type == 'accordionlist') {
                if (this.$store.state[item.entity] != undefined ? this.$store.state[item.entity].loading : false) {
                    result = true;                        
                }
            }
        });

        if (result && this.isNew) this.setDefaultValues();
        return result;
    };

    get loading() {
        let _loading: any = {};

        this.items.forEach(item => {
            if (item.controltype == 'select' || item.controltype == 'radio' || item.controltype == 'checkboxlist' || item.type == 'sortableList' || item.controltype == 'button')
                _loading[item.entity] = (this.$store.state[item.entity] != undefined ? this.$store.state[item.entity].loading : false);
        });
        return _loading;
    };

    get options() {
        let _options: any = {};
        this.items.forEach(item => {
            if (item.controltype == 'select' || item.controltype == 'radio' || item.controltype == 'checkboxlist' ||
                item.type == 'sortablelist' || item.type == 'treeview' || item.type == 'accordionlist' || item.prefixSelect == true) {
                _options[item.code] = [];
                let _tmpOptions = [];

                if (this.$store.state[item.entity] != undefined) {                        
                    if (item.generalType != undefined) {
                        _tmpOptions = Util.abp.list.getList(item.generalType);
                    }
                    else if (item.autocomplete) {
                        _tmpOptions = item.options;
                    }
                    else {
                        if (item.sourceIndex != undefined)
                            _tmpOptions = this.$store.state[item.entity][item.sourceList][item.sourceIndex];
                        else 
                            _tmpOptions = this.$store.state[item.entity][item.sourceList];
                    }
                }
                else if (item.options != undefined) {
                    _tmpOptions = item.options;
                } 
                //filtrar opciones si tiene datos
                if (isArray(item.filterVal)) {
                    _tmpOptions = _tmpOptions.filter((op: any) => {
                        return item.filterVal.indexOf(op[item.filterParam]) >= 0;
                    });
                }

                _options[item.code] = _tmpOptions;
            }
        });
        return _options;
    };

    gSearch() {
        let _search: any = {};
        this.items.forEach(item => {
            if (item.controltype == 'select' && item.autocomplete) {
                _search[item.code] = null;
            }
        });
        return _search;
    };

    search: any = this.gSearch();        

    reset() {
        let $this = this;
        //(this.$refs.vform as any).resetValidation();
        if (this.$refs.datefield != undefined) {
            (this.$refs.datefield as any).forEach((item, index) => {
                item.date = '';
            });
        }
        (this.$refs.vform as any).reset();
        this.$emit('input', false);            
    };

    validate() {
        return (this.$refs.vform as any).validate();
    };       

    async loadOptions(item) {
        await this.$store.dispatch({
            type: item.source,
            data: item.params
        });
        if (item.onloaded != undefined) item.onloaded();
    }

    async getItem(item) {
        let result = await this.$store.dispatch({
            type: item.entity + '/get',
            id: this.model[item.prop]
        });
        if (isArray(result))
            item.options.push(...result);
        else
            item.options.push(result);
    }

    //@Emit('on-init')
    initForm() {
        for (var i = 0; i < this.items.length; i++) {
            if (this.items[i].loadOptions) {
                this.items[i].loadOptions = false;
                this.loadOptions(this.items[i]);
            } else if (this.items[i].generalType != undefined && (this.items[i].options == null || (this.items[i].options != null && this.items[i].options.length == 0))) {
                this.items[i].options = Util.abp.list.getList(this.items[i].generalType);
            } else {
                if (this.items[i].onloaded != undefined) this.items[i].onloaded();
            }
        }

        this.setDefaultValues();
        this.$emit('on-init');
    }

    setDefaultValues() {
        let $this = this;
        this.items.forEach(item => {
            if ((item.defaultValue || item.defaultValue == '0') && $this.isNew) {
                //&& !$this.$store.state[item.entity].loading
                if ((item.controltype == 'select' || item.controltype == 'radio' || item.prefixSelect)) {
                    if (item.defaultValue == '0') {
                        let value = null;

                        if (item.returnObject)
                            value = $this.options[item.code][0];
                        else if ($this.options[item.code][0] != undefined)
                            value = $this.options[item.code][0]['id'];

                        if (item.prefixSelect)
                            $this.model[item.prefixProp] = value;
                        else
                            $this.model[item.prop] = value;
                    }
                } else if (item.controltype == 'date') {
                    if (item.defaultValue == 'today') {
                        let TodayDate = new Date();
                        $this.model[item.prop] = TodayDate.toISOString();;
                    }
                } else {
                    $this.model[item.prop] = item.defaultValue;
                }
            }
            else if (!$this.isNew) {
                if (item.controltype == 'select' && item.autocomplete) {
                    if (item.autocomplete) {
                        let mod = Util.getEntity(this, item.entity, $this.model[item.prop]);
                        if (mod == null) {
                            if (item.autoLoad == true) $this.getItem(item);
                        } else
                            item.options.push(...this.$store.state[item.entity].activeList);
                    } else {
                        item.options.push($this.model[item.prop]);
                    }
                }
            }

            if (item.controltype == 'ubigeo' && ($this.$refs[item.prop] as any)[0] != null) {
                setTimeout(() => {
                    ($this.$refs[item.prop] as any)[0].init($this.model[item.prop]);
                }, 100);
            }
        });
    };

    mounted() {
        this.setDefaultValues();
        this.items.forEach(item => {
            if (item.controltype == 'select' && item.autocomplete) {
                let method = 'search.' + item.code;
                this.$watch(method, async (val, oldVal) => {
                    val = String(val);
                    let listExitst = Util.findByParam(this.options[item.code], item.itemText, val.toUpperCase());

                    if (val == '0' || val == null || (val != null && val.length < item.searchLen) || item.readonly || item.disabled || listExitst.length > 0) return;
                    item.params.query = val;
                    item.params.filter = item.itemText;
                    let options = await this.$store.dispatch({
                        type: item.source,
                        data: item.params
                    });

                    if (options.length > 0) {
                        item.options = options;
                        this.options[item.code] = item.options;
                    }
                }, { deep: true });
            }
        });
        setTimeout(() => this.initForm(), 200);
    }

    created() {
    }
}
